// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: 'https://64305da56c0048ce9168e51fe9d69891@o426384.ingest.sentry.io/5447685',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    release: 'web-staging-vigilan-sdd@1.1.0-staging.9'
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}