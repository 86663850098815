import { User } from '@mindhiveoy/firebase-schema/';
import { AuthError } from '@shared/schema';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { ApplicationState } from '..';
import firebase from '../../../schema';

export enum AuthActionType {
  UPDATE_USER = 'UPDATE_USER',
  UPDATE_FIREBASE_USER = 'UPDATE_FIREBASE_USER',
  ADD_ERROR = 'ADD_ERROR',
  LOADING = 'LOADING',
}

export type AuthAction = AnyAction | UpdateUserAction;

export interface UpdateUserAction extends AnyAction {
  type: AuthActionType.UPDATE_USER;
  payload: firebase.User;
}

export interface AuthState {
  /**
   * Signed in user if null or undefined then no logged user
   */
  user?: User;
  firebaseUser?: firebase.User;
  authError?: AuthError;
  loading: boolean;
}

export const initialState: AuthState = {
  loading: true,
};

export const useAuth = () => {
  const firebaseUser = useSelector<ApplicationState, firebase.User>(state => state.auths.firebaseUser);
  const user = useSelector<ApplicationState, User>(state => state.auths.user);
  const loading = useSelector<ApplicationState, boolean>(state => state.auths.loading);
  const dispatch = useDispatch();

  const updateUser = (payload: User) => {
    dispatch({
      type: AuthActionType.UPDATE_USER,
      payload,
    });
  };

  const updateFirebaseUser = (payload: firebase.User) => {
    dispatch({
      type: AuthActionType.UPDATE_FIREBASE_USER,
      payload,
    });
  };

  const updateLoading = (payload: boolean) => {
    dispatch({
      type: AuthActionType.LOADING,
      payload,
    });
  };

  return {
    user, updateUser, loading, updateLoading, firebaseUser, updateFirebaseUser,
  };
};

export const auths = (state: AuthState = initialState, action: AuthAction) => {
  // tslint:disable-next-line: no-small-switch
  switch (action.type) {
    case AuthActionType.UPDATE_USER:
      return {
        ...state,
        user: action.payload,
      };
    case AuthActionType.UPDATE_FIREBASE_USER:
      return {
        ...state,
        firebaseUser: action.payload,
      };
    case AuthActionType.LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case AuthActionType.ADD_ERROR:
      return {
        ...state,
        authError: action.payload,
      };
    default:
      return state;
  }
};

