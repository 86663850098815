export interface LocalizedText {
  [localeId: string]: string;
}

export interface LocalizationMenuItem {
  id: string;
  title: string;
}

export enum LocalizedCountryCodes {
  FI = 'fi',
  EN = 'en',
}
