import { AuthenticationDialogStatus } from '@shared/schema';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { UpdateDialogStatusAction } from './navigationDialogStatusUpdatedAction';
import { ApplicationState } from '..';

export enum NavigationActionType {
  SHOW_AUTHENTICATION_DIALOG = 'show_authentication_dialog',
  IS_LOADING = 'is_loading',
}

export type NavigationAction = AnyAction | UpdateDialogStatusAction;

export interface UpdateAuthDialogStatusAction extends AnyAction {
  type: NavigationActionType.SHOW_AUTHENTICATION_DIALOG;
  payload: AuthenticationDialogStatus;
}

export interface NavigationState {
  /* Status for which dialog will be open */
  authenticationDialogState?: AuthenticationDialogStatus;
  isLoading: boolean;
}

export const initialState: NavigationState = {
  authenticationDialogState: undefined,
  isLoading: false,
};

export const useNavigations = () => {
  const authenticationDialogState = useSelector<ApplicationState, AuthenticationDialogStatus>(
    state => state.navigations.authenticationDialogState
  );
  const dispatch = useDispatch();

  const updateAuthDialogState = (status: AuthenticationDialogStatus, isLoading: boolean) => {
    dispatch({
      type: NavigationActionType.SHOW_AUTHENTICATION_DIALOG,
      payload: { status, isLoading, },
    });
  };

  return { authenticationDialogState, updateAuthDialogState, };
};

export const navigations = (state: NavigationState = initialState, action: NavigationAction) => {
  // tslint:disable-next-line: no-small-switch
  switch (action.type) {
    case NavigationActionType.SHOW_AUTHENTICATION_DIALOG:
      return {
        ...state,
        authenticationDialogState: action.payload.status,
        ...action.payload.isLoading !== undefined && {
          isLoading: action.payload.isLoading,
        },
      };

    case NavigationActionType.IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
};
