import styled from '@emotion/styled';
import { CircularProgress } from '@material-ui/core';

const LoaderContainer = styled.div<LoaderSpinnerProps>`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  ${props => `min-height: ${props.minHeigth}px`}
`;

interface LoaderSpinnerProps {
  minHeigth?: number;
}

const LoaderSpinner = (props: LoaderSpinnerProps) =>
  <LoaderContainer {...props}>
    <CircularProgress color="primary" />
  </LoaderContainer>;

export default LoaderSpinner;
