import { WithId } from '../index';
import { WithAddress } from '../common';

export type MemberId = string;

export interface Member extends Partial<WithAddress> {
  name: string;
  email: string;
  role: MemberRole;
  photoURL?: string;
  phone?: string;
  avatarUrls?: {
    thumbs: {
      [size: string]: string;
    }
  };

  /**
   * Connected user id from users/:id
   */
  uid?: string;
  status?: MemberStatus;
}

export interface MemberInfo {
  id: string;
  name: string;
  description?: string;
}

export enum MemberStatus {
  ACTIVE = 'a',
  PENDING = 'p',
  CLOSED = 'c'
}

export enum MemberRole {
  ADMIN = 'a',
  MANAGER = 'm',
  USER = 'u'
}

export type MemberWithId = Member & WithId<MemberId>;