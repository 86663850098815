
export enum Decoder {
  VIGILAN_HUMID = 'VIGILAN_HUMID',
  ELSYS = 'ELSYS',
}

/**
 * Concrete type
 */
export enum ConcreteType {
  CONCRETE = 0,
  NP_CONCRETE = 1,
  PLASTER = 2,
  WET_ROOM = 3,
}

export const CONCRETE_TYPES = {
  [ConcreteType.CONCRETE]: 'concrete', // t('concrete')
  [ConcreteType.NP_CONCRETE]: 'np_concrete', // t('np_concrete')
  [ConcreteType.PLASTER]: 'plaster', // t('plaster')
  [ConcreteType.WET_ROOM]: 'wet_room', // t('wet_room')
};

/**
 * Device profile ID's 
 */
export enum DeviceProfile {
  ELSYS_STANDARD = 'Elsys/standard',

  /**
   * This is Unknown in Digita's list
   */
  VIGILAN = 'LORA/GenericA.1_ETSI_Rx2-SF12_noADR',
}

/**
 * You can get list of device profiles from DX api
 * ThingPark DX Core API - deviceProfiles
 */
export const deviceProfiles = {
  [DeviceProfile.VIGILAN]: {
    'id': 'LORA/GenericA.1_ETSI_Rx2-SF12_noADR',
    'name': 'Vigilan', // LoRaWAN 1.0 - class A - ETSI - Rx2_SF12 - no ADR 
    'typeMAC': 'LoRaMAC',
  },
  [DeviceProfile.ELSYS_STANDARD]: {
    'id': 'Elsys/standard',
    'name': 'Elsys standard',
    'typeMAC': 'LoRaMAC',
  },
};

export interface Device {
  name: string;
  deviceUid: string;
  deviceProfileId?: DeviceProfile;
  applicationKey?: string;

  /**
   * Concrete type is used to calculate more precise values from raw data
   */
  concreteType?: ConcreteType;

  /**
   * Decoder what to use when decode event from Digita
   */
  decoder?: Decoder;
}