import {
  doc, User, DataType, FirestoreSchemaModel, FieldType, schemaUserFields
} from '@mindhiveoy/firebase-schema';
import { Device } from './device';
import { Organization } from './organization';

export const schema: FirestoreSchemaModel = {
  packages: ['auth', 'firestore', 'functions', 'messaging', 'storage',],

  collections: {
    organizations: {
      doc: doc<Organization>({
        paramId: 'orgId',
        info: 'Organization in this project represents a single company',
        fields: {
          name: {
            dataType: DataType.Text,
            type: FieldType.Descriptor,
            required: true,
          },
          devicesCount: {
            dataType: DataType.Integer,
            type: FieldType.Descriptor,
          },
          devicesList: {
            dataType: DataType.Array,
            type: FieldType.Descriptor,
          },
          vatId: {
            dataType: DataType.Text,
            type: FieldType.Descriptor,
          },
          description: {
            dataType: DataType.Text,
            type: FieldType.Descriptor,
          },
        },
        collections: {
          devices: {
            doc: doc<Device>({
              paramId: 'devId',
              info: 'Single device by ID',
              fields: {
                name: {
                  type: FieldType.Descriptor,
                  dataType: DataType.Text,
                },
                deviceUid: {
                  type: FieldType.Descriptor,
                  dataType: DataType.Text,
                },
                deviceProfileId: {
                  type: FieldType.Descriptor,
                  dataType: DataType.Text,
                },
                applicationKey: {
                  type: FieldType.Descriptor,
                  dataType: DataType.Text,
                },
                concreteType: {
                  type: FieldType.Descriptor,
                  dataType: DataType.Integer,
                },
                decoder: {
                  type: FieldType.Descriptor,
                  dataType: DataType.Text,
                },
              },
            }),
          },
        },
      }),
    },
    users: {
      doc: doc<User>({
        paramId: 'uid',
        info: 'All users using Vigilan in any role',
        fields: {
          ...schemaUserFields,
          home: {
            dataType: DataType.Text,
            type: FieldType.Descriptor,
          },
        },
      }),
    },
  },
};
