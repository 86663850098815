import { User as BaseUser } from '@mindhiveoy/firebase-schema';

export enum AuthenticationDialogStatus {
  SIGN_IN = 'sign_in',
  SIGN_UP = 'sign_up',
  FORGOT_PASSWORD = 'forgot_password',
  WELCOME = 'welcome',
}

export enum RegisterResponseStatus {
  OK = 'ok',
  ERROR = 'failed',
}

export interface AuthError {
  type: string;
  message: string;
}

// Needed to make web build to work. Can be removed when module have other things besides types
export const DUMMY = 'daa';

export interface User extends BaseUser {

};