export declare const emailInvitationValid = 1209600;

export interface EmailInvitation {
  /**
   * Server timestamp when sended.
   */
  sended?: any;
  /**
   * How long invitation is valid.
   * for default use: emailInvitationValid
   */
  valid?: number;
  /**
   *  Email address where invitation is sended.
   */
  email: string;
  memberId: string;
  organization: string;
  /**
   * Status of invitation
   */
  status: InvitationStatus;

  /**
   * Server timestamp when used.
   */
  used?: any;

  /**
   * If there is error object
   */
  error?: any;
}

export interface EmailInvitationData {
  id: string;
}

export declare enum InvitationStatus {
  PROCESSING = 'PROCESSING',
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  USED = 'USED',
  VALID = 'VALID',
  NOT_VALID = 'NOT_VALID',
  ERROR = 'ERROR'
}

export declare enum EmailInvitationValidErrorCode {
  NO_ID_GIVEN = 1,
  INVITATION_DONT_EXIST = 2,
  INVITATION_DATA_IS_NOT_VALID = 3,
  INVITATION_IS_USED = 4,
  INVITATION_IS_OLD = 5
}

export interface EmailInvitationValidReturnData {
  /**
   * Invitation status
   */
  status: InvitationStatus;
  /**
   * Error code
   */
  errorCode?: EmailInvitationValidErrorCode;
}

export enum VerifyStatus {
  OK = 'ok',
  FAILED = 'failed',
}

export interface VerifyStatusData {
  status: VerifyStatus;
}