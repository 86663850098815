import red from '@material-ui/core/colors/red';
import { createTheme, Theme } from '@material-ui/core/styles';

// Theme interface for emotions styled
export interface ThemeProps {
  theme: Theme;
}

// Create a theme instance.
const theme = createTheme({
  typography: {
    fontFamily: [
      'Roboto',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Helvetica Neue"',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#0b70b4',
      contrastText: 'rgba(255,255,255,0.9)',
    },
    secondary: {
      main: '#f88a3d',
      contrastText: 'rgba(255,255,255,0.9)',
    },
    error: {
      main: red.A400,
    },
    text: {
      primary: 'rgba(0,0,0,0.7)',
    },
    background: {
      default: '#f7f7f7',
    },
  },
});

export default theme;
