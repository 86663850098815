import { combineReducers, AnyAction, Store } from 'redux';
import { auths, AuthAction } from './auth';
import { navigations, NavigationAction } from './navigation';

const rootReducer = combineReducers({
  navigations,
  auths,
});

const generateInitialState = (): ApplicationState => {
  const result = {} as ApplicationState;
  Object.keys(rootReducer).forEach(key => {
    result[key] = rootReducer[key](undefined, { type: 'test', });
  });
  return result;
};

export type AppAction = AnyAction | NavigationAction | AuthAction;

export type ApplicationState = ReturnType<typeof rootReducer> & Store;

export interface WithStore {
  store: Store<ApplicationState, AppAction>;
}

export const storeInitialState = generateInitialState();

export default rootReducer;
