import { PropsWithChildren, useEffect, useState } from 'react';
import { firebaseApp } from 'schema';
import { MemberStatus } from '@mindhiveoy/firebase-schema';
import { useAuth } from 'reducers/auth';
import { NavigationPath, PUBLIC_PATHS } from '@shared/schema';
import { useRouter } from 'next/router';
import LoaderSpinner from 'components/LoaderSpinner';

const Auth = ({ children, }: PropsWithChildren<any>) => {
  const { updateFirebaseUser, updateUser, } = useAuth();
  const [loading, setLoading,] = useState<boolean>(true);
  const router = useRouter();

  useEffect(() => {
    const cancelOnAuthStateChanged = firebaseApp().auth().onAuthStateChanged(async firebaseUser => {
      try {
        !loading && setLoading(true);

        if (firebaseUser) {
          // Get user from db
          const userDoc = await firebaseApp().firestore()
            .collection('users')
            .doc(firebaseUser.uid)
            .get();

          // Check if user exist in database
          if (!userDoc.exists) {
            throw new Error('No user found');
          }
          const user = userDoc.data();

          // Check if home is valid
          const selectedOrganization = user?.organizations[user?.home];
          if (!selectedOrganization) {
            throw new Error('No organization found');
          }

          if (selectedOrganization.status !== MemberStatus.ACTIVE) {
            throw new Error('Member is not active');
          }

          // Everything should be ok, update firebase user and user to redux
          updateFirebaseUser(firebaseUser);
          updateUser(user);
          setLoading(false);
        } else {
          if (!PUBLIC_PATHS.includes(router.asPath as NavigationPath)) {
            await router.push({
              pathname: NavigationPath.LOGIN,
              ...router.pathname !== NavigationPath.HOME && {
                query: {
                  next: router.pathname,
                },
              },
            });
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    });

    return () => cancelOnAuthStateChanged();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '100vh', }}>
      <LoaderSpinner />
    </div>;
  }

  return children;
};

export default Auth;