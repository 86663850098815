import rootReducer, { ApplicationState, storeInitialState } from 'reducers';
import { createStore } from 'redux';
import { devToolsEnhancer } from 'redux-devtools-extension';

// pass an optional param to rehydrate state on app start
let store;

export const initializeStore = (
  preloadedState: ApplicationState = storeInitialState
) => {
  if (store) {
    return store;
  }

  // This if else is because nextjs 
  if (process.env.NODE_ENV === 'development') {
    store = createStore(
      rootReducer,
      preloadedState,
      devToolsEnhancer({})
    );
  } else {
    store = createStore(
      rootReducer,
      preloadedState
    );
  }

  return store;
};

export default initializeStore;