import firebase from 'firebase/app';
import { collectionFieldOverride } from '@mindhiveoy/firebase-schema-web-overrides';
import { schema } from '../@shared/schema/schema';

require('firebase/auth');
require('firebase/firestore');
require('firebase/functions');
require('firebase/messaging');
require('firebase/storage');
import config from '../config';
import { proxyOrganizations , OrganizationsCollectionReference } from './organizations';
import { proxyUsers , UsersCollectionReference } from './users';

type FirestoreDatabaseCollectionOverload = {
  (collectionName: 'organizations'): OrganizationsCollectionReference;
  (collectionName: 'users'): UsersCollectionReference;
}

export type FirestoreDatabase = Omit<firebase.firestore.Firestore, 'collection'> & {
  /**
   * Gets a `CollectionReference` instance that refers to the collection at
   * the specified path.
   *
   * @param collectionName A name of the collection.
   * @return The `CollectionReference` instance.
   */
  collection: FirestoreDatabaseCollectionOverload;
}
  
export interface FirebaseApp extends Omit<firebase.app.App, 'firestore'> {  
  firestore(): FirestoreDatabase;
}
  
// TODO Activate proxy
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const proxyApp = (app: firebase.app.App): FirebaseApp => {
  const firestore = proxyFirestore(app);
  (app as any).firestore = () => firestore as any;
  return app as any as FirebaseApp;
};
  
const proxyFirestore = (app: firebase.app.App): FirestoreDatabase => {  
  const organizations = proxyOrganizations(
    app.firestore() as any,
    schema,
    schema.collections.organizations,
    app.firestore().collection('organizations'));
  const users = proxyUsers(
    app.firestore() as any,
    schema,
    schema.collections.users,
    app.firestore().collection('users'));
  
  const _collection = collectionFieldOverride(
    app.firestore() as any,
    schema,
    undefined as any, {
      organizations: organizations as any, 
      users: users as any, 
  });
  
  const firestoreProxy = new Proxy(app.firestore(), {
    get: (item, property) => {

      switch (property) {

        case 'doc':
          throw new Error('References to a document from root level are not allowed. ' +
            'Please reference only immediate child documents under the collection where they are located. ')

        case 'collection':
          return _collection;

        default:
          return (item as any)[property];
      }
    },
  });
  return firestoreProxy as any as FirestoreDatabase;
};

let app: FirebaseApp;

export const firebaseApp = () => {
  if (app) {
    return app;
  }

  
  const isDevelopmentMode = config.environment.target === 'development' && !config.environment.disableEmulator; 

  try {
    app = firebase.initializeApp(config.firebase) as any as FirebaseApp;

    if (!isDevelopmentMode) {
      /*
        * Firebase cloud functions's version of auth do not have setPersistence function. So we need to check its
        * existence dynamically and also call it dynamically
        */
      const auth = firebase.auth();
      if (typeof window !== 'undefined' && auth.setPersistence) {
        firebase.firestore().enablePersistence({ synchronizeTabs: true, });
        auth
          .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
          .then(() => {
            console.log('Local auth persistence enabled');
          })
          .catch((error: any) => {
            console.error(error);
          });
      }
    }
  } catch (err) {
    // we skip the "already exists" message which is
    // not an actual error when we're hot-reloading
    if (!/already exists/.test(err.message)) {
      console.error('Firebase initialization error', err.message);
    }
  }

  // TODO include proxy logic. This is just a temporal build to give more time to do the actual framework
  // app = proxyApp(firebase as firebase.app.App) as any;
  if (isDevelopmentMode && schema.packages) {
    if (schema.packages.indexOf('auth') >= 0) {
      app.auth().useEmulator('http://localhost:9099');
      console.info('Running firebase auth emulator on port 9099.')
    }
    if (schema.packages.indexOf('firestore') >= 0) {
      app.firestore().useEmulator('localhost', 8080);
      console.info('Running firebase firestore emulator on port 8080.')
    }
    if (schema.packages.indexOf('functions') >= 0) {
      app.functions().useEmulator('localhost', 5001);
      console.info('Running firebase functions emulator on port 5001.')
    }
  }

  return app;
};

/**
 * Please, use this reference only to access Timestamp and other structural elements of the firebase api. Use
 * @see firebaseApp() -function to access the app itself.
 */
export default firebase;
