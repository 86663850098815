/**
 * # WEB DEVELOPMENT
 *
 * @copyright Mindhive Oy 2019
 */
import { AppConfig } from '@shared/schema';

/**
 * NOTICE: These values are all public. So NO private keys here 
 */

const config: AppConfig = {
  environment: {
    target: 'staging',
    build: '$BUILD_NUMBER',
    version: '$VERSION_NUMBER',
    sha1: '',
    branch: '',
    tag: '',
    webHostAddress: 'http://localhost:3000',
  },
  firebase: {
    apiKey: 'AIzaSyD1UlE0-SkrZvbiudAYnNfGESBw4gLhypU',
    authDomain: 'vigilan-sdd-staging.firebaseapp.com',
    databaseURL: 'https://vigilan-sdd-staging.firebaseio.com',
    projectId: 'vigilan-sdd-staging',
    storageBucket: 'vigilan-sdd-staging.appspot.com',
    messagingSenderId: '444975259572',
    appId: '1:444975259572:web:26a9131c9fac6954ff9ce1',
    measurementId: 'G-73WHH2H097',
  },
  google: {
    credentials: {
      android: '',
      ios: '',
    },
    apiKeys: {
      map: '',
      distanceMatrix: '',
    },
  },
  facebook: {},
};

export default config;
