export enum NavigationPath {
  HOME = '/',
  LOGIN = '/login',

  DEVICES = '/devices',
  DATA = '/data',
  PROFILE = '/profile',
}

/**
 * These paths don't need credential check, onAuthStateChange we are going to skip these routes
 */
export const PUBLIC_PATHS = [
  NavigationPath.LOGIN,
];