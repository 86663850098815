import CssBaseline from '@material-ui/core/CssBaseline';
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { MakeStore, createWrapper } from 'next-redux-wrapper';
import Head from 'next/head';
import { initializeStore } from 'reducers/reduxStore';
import theme from 'theme';
import { ApplicationState } from 'reducers';
import { AppProps } from 'next/dist/next-server/lib/router/router';
import Auth from 'components/Auth';
import { appWithTranslation } from 'next-i18next';

// create a makeStore function
const makeStore: MakeStore<ApplicationState> = () => initializeStore();

// export an assembled wrapper
export const wrapper = createWrapper<ApplicationState>(makeStore, { debug: false, });

const MyApp = ({ Component, pageProps, }: AppProps) => {

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <>
      <Head>
        <meta charSet='utf-8' />
        <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
        <meta name='viewport' content='width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no' />
        <meta name='description' content='Vigilan sensor data dasboard' />
        <meta name="theme-color" content={theme.palette.primary.main} />
        <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap" rel="stylesheet" />
      </Head>
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Auth>
            <Component {...pageProps} />
          </Auth>
        </ThemeProvider>
      </StylesProvider>
    </>
  );
};

export default wrapper.withRedux(appWithTranslation(MyApp));
